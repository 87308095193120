import React from "react";

const AboutPage=() => (
  <section className="mybody-section about-body">
    <h2>About</h2>
    <p>
      This app is a proof of concept tool for managing website updates that uses React, Redux, React Router, and many other helpful libraries. It is a work in progress. The next steps will include adding a pdf generator to document the selected topics targeted for updates and maintenance.
    </p>
  </section>
);

export default AboutPage;
